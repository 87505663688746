// src/articlesData.js

export const articles = [
    {
      id: 1,
      title: "News",
      content:
        "Full content of the article. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo reiciendis delectus vitae, aliquid sit iure dolorum commodi eum numquam voluptate!",
      date: "August 19, 2024",
      image: "https://via.placeholder.com/600x400",
    },
    {
      id: 2,
      title: "News",
      content:
      "Full content of the article. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo reiciendis delectus vitae, aliquid sit iure dolorum commodi eum numquam voluptate!",
      date: "August 17, 2024",
      image: "https://via.placeholder.com/600x400",
    },
    {
      id: 3,
      title: "News",
      content:
      "Full content of the article. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo reiciendis delectus vitae, aliquid sit iure dolorum commodi eum numquam voluptate!",
      date: "August 15, 2024",
      image: "https://via.placeholder.com/600x400",
    },
  ];
  