import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Loader from './Loader';
import { articles as initialArticles } from "./pages/Journal/articlesData"; // Import initial articles data
import ScrollToTop from './scrolltop'; //

// Lazy load all components
const Footer = lazy(() => import('./components/home/Footer/Footer'));
const FooterBottom = lazy(() => import('./components/home/Footer/FooterBottom'));
const Header = lazy(() => import('./components/home/Header/Header'));
const HeaderBottom = lazy(() => import('./components/home/Header/HeaderBottom'));
const SpecialCase = lazy(() => import('./components/SpecialCase/SpecialCase'));

const About = lazy(() => import('./pages/About/About'));
const SignIn = lazy(() => import('./pages/Account/SignIn'));
const SignUp = lazy(() => import('./pages/Account/SignUp'));
const Cart = lazy(() => import('./pages/Cart/Cart'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Home = lazy(() => import('./pages/Home/Home'));
const Journal = lazy(() => import('./pages/Journal/Journal'));
const Offer = lazy(() => import('./pages/Offer/Offer'));
const Payment = lazy(() => import('./pages/payment/Payment'));
const ProductDetails = lazy(() => import('./pages/ProductDetails/ProductDetails'));
const Services = lazy(() => import('./pages/Services/Services'));
const Shop = lazy(() => import('./pages/Shop/Shop'));
const Sales = lazy(() => import('./Admin/Authenticated/Pages/Sales'));
const Reports = lazy(() => import('./Admin/Authenticated/Pages/Reports'));
const Products = lazy(() => import('./Admin/Authenticated/Pages/Products'));
const Vendors = lazy(() => import('./Admin/Authenticated/Pages/Vendors'));
const Service = lazy(() => import('./Admin/Authenticated/Pages/service'));
const Analytics = lazy(() => import('./Admin/Authenticated/Pages/Analytics'));
const Admin = lazy(() => import('./Admin/Authenticated/home/home'));
const JournalDetail = lazy(() => import('./pages/Journal/JournalDetails'));
const JournalCRUD = lazy(() => import('./pages/Journal/JournalCRUD'));
const Vendor = lazy(() => import('../src/Vendor/vendor'));


const Layout = () => (
  <Suspense fallback={<Loader />}>
     <ScrollToTop />
    <Header />
    <HeaderBottom />
    <SpecialCase />
    <Outlet />
    <Footer />
    <FooterBottom />
  </Suspense>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/" element={<Layout />}>
     
        <Route index element={<Suspense fallback={<Loader />}><Home /></Suspense>} />
        <Route path="shop" element={<Suspense fallback={<Loader />}><Shop /></Suspense>} />
        <Route path="services" element={<Suspense fallback={<Loader />}><Services /></Suspense>} />
        <Route path="about" element={<Suspense fallback={<Loader />}><About /></Suspense>} />
        <Route path="contact" element={<Suspense fallback={<Loader />}><Contact /></Suspense>} />
        <Route path="journal" element={<Suspense fallback={<Loader />}><Journal /></Suspense>} />
        <Route path="/journal/:id" element={<Suspense fallback={<Loader />}><JournalDetail /></Suspense>} />
        <Route path="journal-crud" element={<Suspense fallback={<Loader />}><JournalCRUD /></Suspense>} />
        <Route path="offer" element={<Suspense fallback={<Loader />}><Offer /></Suspense>} />
        <Route path="product/:_id" element={<Suspense fallback={<Loader />}><ProductDetails /></Suspense>} />
        <Route path="cart" element={<Suspense fallback={<Loader />}><Cart /></Suspense>} />
        

        <Route path="paymentgateway" element={<Suspense fallback={<Loader />}><Payment /></Suspense>} />
      </Route>
      <Route path="signup" element={<Suspense fallback={<Loader />}><SignUp /></Suspense>} />
      <Route path="signin" element={<Suspense fallback={<Loader />}><SignIn /></Suspense>} />
      <Route path="Admin" element={<Suspense fallback={<Loader />}><Admin /></Suspense>} />
      <Route path="sales" element={<Suspense fallback={<Loader />}><Sales /></Suspense>} />
      <Route path="reports" element={<Suspense fallback={<Loader />}><Reports /></Suspense>} />
      <Route path="products" element={<Suspense fallback={<Loader />}><Products /></Suspense>} />
      <Route path="service" element={<Suspense fallback={<Loader />}><Service /></Suspense>} />
      <Route path="vendors" element={<Suspense fallback={<Loader />}><Vendors /></Suspense>} />
      <Route path="analytics" element={<Suspense fallback={<Loader />}><Analytics /></Suspense>} />
      <Route path="vendor" element={<Suspense fallback={<Loader />}><Vendor /></Suspense>} />
    </React.Fragment>
  )
);

function App() {
  const [articles, setArticles] = useState(initialArticles);

  useEffect(() => {
    // Example useEffect for disabling right-click and keyboard shortcuts (commented out)
    const handle = (Event) => {
      Event.preventDefault();
    };

    const handlekey = (Event) => {
      if (
        Event.key === 'F12' ||
        (Event.ctrlKey && Event.shiftKey && Event.key === 'I') ||
        (Event.ctrlKey && Event.shiftKey && Event.key === 'J') ||
        (Event.ctrlKey && Event.key === 'U')
      ) {
        Event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handle);
    document.addEventListener('keydown', handlekey);

    return () => {
      document.removeEventListener('contextmenu', handle);
      document.removeEventListener('keydown', handlekey);
    };
  }, []);

  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
